<template>
  <div v-if="item"
       class="comp-campaign"
       v-bind:style="{ backgroundImage: 'url(' + (item.image ? item.image.background : '') + ')' }">
    <div class="inner">
      <div class="caption">{{ item.name }}</div>
      <div v-if="item.display_start" class="date">
      <span>
        {{
            (item.display_start ? convertUTCToClientTime(item.display_start).format('YYYY/MM/DD') : '') +
            ' - ' +
            (item.display_end ? convertUTCToClientTime(item.display_end).format('YYYY/MM/DD') : '')
          }}
      </span>
      </div>
      <div class="campaign-local">
        <home-localization-map :isReInitHeader="1"></home-localization-map>
      </div>
      <div class="banner">
        <home-popular-deal orderNow=true></home-popular-deal>
      </div>
      <footer>
        <ul class="links">
          <li>
            <router-link class="no-underline px-10 btn btn-link-footer" to="/">{{$t('home')}}</router-link>
          </li>
          <li>
            <a href="javascript: void(0);" class="no-underline px-10 btn btn-link-footer"
               @click="$root.$emit('onProcessChangeCategory')">{{$t('all_deal')}}</a>
          </li>
          <li>
            <router-link class="no-underline px-10 btn btn-link-footer" to="/contact">{{$t('contact_us')}}</router-link>
          </li>
          <li>
            <router-link class="no-underline px-10 btn btn-link-footer" to="/terms">{{$t('terms')}}</router-link>
          </li>
          <li>
            <router-link class="no-underline px-10 btn btn-link-footer" to="/privacy">{{$t('footer.cft_sec')}}
            </router-link>
          </li>
        </ul>
        <span class="copy">{{ $t('copyright', { year: (new Date().getFullYear()) }) }}</span>
      </footer>
    </div>
  </div>
</template>
<script>

export default {
  components: {
    HomeLocalizationMap: () => import('@/components/home/HomeLocalizationMap'),
    HomePopularDeal: () => import('@/components/home/HomePopularDeal')
  },
  mounted () {
    this.initData()
    this.eventTrackingScreenLoad(this, {screen: {'name': 'campaign.list', 'type': 'campaign'}})
    const body = document.getElementsByTagName('body')[0]
    body.id = 'page-campaign'
  },
  beforeCreate: function () {

  },
  beforeDestroy: function () {
    const body = document.getElementsByTagName('body')[0]
    body.id = ''
  },
  data () {
    return {
      item: null
    }
  },
  methods: {
    initData () {
      let vm = this
      let campaignName = this.$route.params.campaignName
      let data = {'url': campaignName}

      vm.axios.apiCampaign.getCampaign(data, function (response) {
        let item = response.data.data.item

        if (!item.image.background) {
          item.image.background = 'https://deo515rx9pz7c.cloudfront.net/fcds/images/landingpage/PHMY_ramadhanraya_20190425.jpg'
        }

        vm.item = item
      })
    }
  }
}

</script>
<style scoped>
  .app-bg {
    background: white !important;
  }

  .mycontainer-main {
    max-width: 1000px;
    margin: auto;
  }

  .campaign-item {
    width: calc(50% - 12px);
    margin: 6px;
  }
</style>
